import React from 'react';
import WhyAdeoImg from "../assets/images/hero-thumb-4.png";
import ShapeOne from "../assets/images/shape/shape-15.png";
import ShapeTwo from "../assets/images/shape/shape-14.png";
import ShapeThree from "../assets/images/shape/shape-13.png";

const WhyAdeo = () => {
    return (
    <div id="WhyAdeo">
            <section class="appie-hero-area appie-hero-3-area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="appie-hero-content text-center">
                                <h1 class="appie-title">AVAILABLE PROGRAMMES </h1>
                                <p>Offline, Self-Paced & Resourceful.</p>
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-1.svg" alt="" />
                                                </div>
                                                <h3 class="title">BECE</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-2.svg" alt="" />
                                                </div>
                                                <h3 class="title">WASSCE</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-3.svg" alt="" />
                                                </div>
                                                <h3 class="title">IGCSE</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style={{marginTop: 3 + 'em'}}>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-4.svg" alt="" />
                                                </div>
                                                <h3 class="title">SAT</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-4.svg" alt="" />
                                                </div>
                                                <h3 class="title">GRE</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="appie-single-counter mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                                            <div class="counter-content">
                                                <div class="icon">
                                                    <img src="assets/images/icon/counter-icon-4.svg" alt="" />
                                                </div>
                                                <h3 class="title">IB</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="thumb mt-100 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                                    <img src={WhyAdeoImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section class="appie-download-area pt-150 pb-160 mb-90" style={{marginTop: 10 + 'em'}}>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="appie-download-content">
                        <span>Download Our App</span>
                        <h3 class="title">Available on<br/> all app stores</h3>
                        <ul>
                            <li>
                                <a href="https://apps.apple.com/us/app/adeo-edu/id1626177775" target='_blank' rel="noreferrer">
                                    <i class="fab fa-apple"></i>
                                    <span>For <span>iOS</span></span>
                                </a>
                            </li>
                            <li>
                                <a class="item-2" href="https://play.google.com/store/apps/details?id=com.ecoach.adeo" target='_blank' rel="noreferrer">
                                    <i class="fab fa-google-play"></i>
                                    <span>For <span>Android</span></span>
                                </a>
                            </li>
                            <li>
                                <a class="item-2" href="#" style={{marginTop: 2 + 'em'}} data-toggle="modal" data-target=".microsoftStoreModal">
                                    <i class="fab fa-windows"></i>
                                    <span>For <span>Windows</span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="download-shape-1">
        <img src={ShapeOne} alt=""  />
        </div>
        <div class="download-shape-2">
        <img src={ShapeTwo} alt=""  />
        </div>
        <div class="download-shape-3">
        <img src={ShapeThree} alt=""  />
        </div>
    </section>
    
    </div>
    )
}

export default WhyAdeo
