import React from 'react';
import IntroImg from "../assets/images/features-thumb-5.png";
import ShapeOne from "../assets/images/shape/shape-15.png";
import ShapeTwo from "../assets/images/shape/shape-14.png";
import ShapeThree from "../assets/images/shape/shape-13.png";

const Intro = () => {
    return (
        <section class="appie-features-area-2 pt-90 pb-100" id="Intro">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="appie-section-title appie-section-title-2 text-center">
                        <h3 class="appie-title">SELF-PACED PREP APP</h3>
                        <p>An Exam Preparation App Like No Other With Dynamic Adaptions, <br/> Analytics And Speed Enhancements To The Users Preference.</p>
                    </div>
                </div>
            </div>
            <div class="row mt-30 align-items-center">
                <div class="col-lg-6">
                    <div class="appie-features-boxes">
                        <div class="appie-features-box-item">
                            <h4 class="title">Learn</h4>
                            <p>Choose your preferred learn mode and accomplish your task how you want it.</p>
                        </div>
                        <div class="appie-features-box-item item-2">
                            <h4 class="title">Testing</h4>
                            <p>Different test types to help give you the practice you need to get better at that topic or subject.</p>
                        </div>
                        <div class="appie-features-box-item item-3">
                            <h4 class="title">Notes</h4>
                            <p>Read concise notes that have been put together to help you easily absorb more information.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="appie-features-thumb wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <img src={IntroImg} />
                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src={ShapeOne} />
        </div>
        <div class="features-shape-2">
            <img src={ShapeTwo} />
        </div>
        <div class="features-shape-3">
            <img src={ShapeThree} />
        </div>
    </section>
    )
}

export default Intro
