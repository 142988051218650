import React from 'react';
import count from "../assets/images/appie-count9-icon1.png";
import {Link} from 'react-router-dom';

const Testimonials = () => {
    return (
    <div>
    <section class="appie-testimonial-area pt-100 pb-160" id="Testimonials">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="appie-testimonial-slider">
                        <div class="appie-testimonial-item text-center">
                            <div class="author-info">
                                <h5 class="title">Melvin , Student</h5>
                                <span>April 14, 2023</span>
                            </div>
                            <div class="text">
                                <p>The adeo app is very engaging. I use it to practise all the time and it helps me improve.</p>
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div class="appie-testimonial-item text-center">
                            <div class="author-info">
                                <h5 class="title">Shadrack Agyiri</h5>
                                <span>March 14, 2023</span>
                            </div>
                            <div class="text">
                                <p>As a teacher I recommend adeo to all my students.I am able to go through their history to easily identify their strengths and weakness</p>
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                        <div class="appie-testimonial-item text-center">
                            <div class="author-info">
                                <h5 class="title">Teresa Smith</h5>
                                <span>May 14, 20223</span>
                            </div>
                            <div class="text">
                                <p>My child loves using adeo and as a parent, the best feature is the summarised report that is sent to me on my ward's activities. it's very easy to catch up with my child even with my busy schedule.</p>
                                <ul>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                    <li><i class="fas fa-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    )
}

export default Testimonials
