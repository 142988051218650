import React from 'react';
import AdeoLogo from "../assets/images/logo-footer.png";
import {Link} from 'react-router-dom';

const Landing = () => {
    return (
        <div>
       <section class="appie-footer-area appie-footer-area-dark">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget">
                        <div class="logo">
                            <a href="#"><img src={AdeoLogo} /></a>
                        </div>
                        {/*<p>Appie WordPress is theme is the last theme you will ever have.</p>
                        <a href="#">Read More <i class="fal fa-arrow-right"></i></a>*/}
                        <div class="social mt-30">
                            <ul>
                                <li><a href="https://web.facebook.com/adeodevs?_rdc=1&_rdr"><i class="fab fa-facebook-f" target='_blank' rel="noreferrer"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="https://www.linkedin.com/company/78372668/" target='_blank'><i class="fab fa-linkedin-in" rel="noreferrer"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Company</h4>
                        <ul>
                            <li><a href="#Intro">Intro</a></li>
                            <li><a href="#Features">Features</a></li>
                            <li><a href="#WhyAdeo">Why Adeo</a></li>
                            <li><a href="#Testimonials">Testimonials</a></li>
                            <li><a href="#FAQ">FAQ</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-navigation">
                        <h4 class="title">Support</h4>
                        <ul>
                            <li><a href="#">Community</a></li>
                            <li><a href="#">Resources</a></li>
                            <li><a href="#">Faqs</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Careers</a></li>
                        </ul>
                    </div>                    
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Get In Touch</h4>
                        <ul>
                            <li><a href="#"><i class="fal fa-envelope"></i> info@adeoedu.com</a></li>
                            <li><a href="#"><i class="fal fa-phone"></i> +(1) 470 939 1412</a></li>
                            <li><a href="#"><i class="fal fa-map-marker-alt"></i> 1881 N Nash Street, Rossyln, Arlington, VA 22209</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
                        <div class="apps-download-btn">
                        <ul>
                            <li><a href="https://apps.apple.com/us/app/adeo-edu/id1626177775" target='_blank'><i class="fab fa-apple"></i> For iOS</a></li>
                            <li><a class="item-2" href="https://play.google.com/store/apps/details?id=com.ecoach.adeo" target='_blank'><i class="fab fa-google-play"></i> For Android</a></li>
                            <li><a class="item-2" href="#" data-toggle="modal" data-target=".microsoftStoreModal"><i class="fab fa-windows"></i> For Windows</a></li>
                        </ul>
                        </div>
                        <div class="copyright-text">
                            <p>Copyright © 2023 Adeo. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    


    
    <div class="back-to-top">
        <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>         
    </div>
    )
}

export default Landing
