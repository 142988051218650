import React from 'react';
import IntroImgTwo from "../assets/images/features-thumb-1.png";
import IntroImgThree from "../assets/images/features-thumb-2.png";
import IntroImgFour from "../assets/images/features-thumb-3.png";
import IntroImgFive from "../assets/images/features-thumb-4.png";
import ShapeSix from "../assets/images/shape/shape-6.png";
import ShapeSeven from "../assets/images/shape/shape-7.png";
import ShapeEight from "../assets/images/shape/shape-8.png";
import {Link} from 'react-router-dom';

const Features = () => {
    return (
    <div style={{marginTop: 6 + 'em'}} id="Features">
    <div class="row">
           <div class="col-lg-12">
               <div class="text-center">
                <h1 class="appie-title">TRACK PROGRESS. GET INSIGHTS</h1>
                   <p>Get Detailed Analysis On Every Activity So As To Measure Your Progress With Time. Spot Trends In Topic Analysis To Know Weaknesses And Strengths And How <br/> These Are Improving With Every Test You Take. Monitor Your Schedule To See How Close You Are To Achieving Your Educational Goals.</p>
               </div>
           </div>
       </div>
         <section class="appie-features-area appie-features-6-area pb-80" id="features" style={{marginTop: 7 + 'em'}}>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="appie-features-tabs-btn">
                        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="fas fa-cog"></i> Works Without Data.</a>
                            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false"><i class="fas fa-exclamation-triangle"></i> Learn At Your Own Pace.</a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false"><i class="fas fa-bell"></i> Detailed Solutions.</a>
                            <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false"><i class="fas fa-lock"></i> Glossary</a>
                        </div>
                    </div>  
                </div>
                <div class="col-lg-9">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="appie-features-thumb text-center wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                    <img src={IntroImgTwo} />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="appie-features-content wow animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Adeo Features</span>
                                        <h3 class="title">Adeo Works Fine <br/> Even Without Data.</h3>
                                        <p>We cache enough data so you can use the application without needing internet all the time.</p>
                                        <a class="main-btn" href="#" data-toggle="modal" data-target=".microsoftStoreModal">Download Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                    <img src={IntroImgThree} />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Adeo Features</span>
                                        <h3 class="title">Learn <br/> At Your Own Pace</h3>
                                        <p>With four different modes to choose from, the best experience is guaranteed with the adeo app that aims to be your learning companion.</p>
                                        <a class="main-btn" href="#" data-toggle="modal" data-target=".microsoftStoreModal">Download Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                        
                                    <img src={IntroImgFour} />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Adeo Features</span>
                                        <h3 class="title">150,000+ <br/> Questions With Solutions.</h3>
                                        <p>A well-resourced test bank to help you practise as much as you want.</p>
                                        <a class="main-btn" href="#" data-toggle="modal" data-target=".microsoftStoreModal">Download Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="appie-features-thumb text-center animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                                    <img src={IntroImgFive} />
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="appie-features-content animated fadeInRight" data-wow-duration="2000ms" data-wow-delay="600ms">
                                        <span>Adeo Features</span>
                                        <h3 class="title">Glossary</h3>
                                        <p>Get terms and definitions at ease. Our glossary offers you a detailed scope of all terms and definitions across every course.</p>
                                        <a class="main-btn" href="#" data-toggle="modal" data-target=".microsoftStoreModal">Download Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="features-shape-1">
            <img src={ShapeSix} />
        </div>
        <div class="features-shape-2">
            <img src={ShapeSeven} />
        </div>
        <div class="features-shape-3">
            <img src={ShapeEight} />
        </div>
    </section>
    </div>
    )
}

export default Features
