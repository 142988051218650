import React from 'react';

const Enterprise = () => {
    return (
    <div id="FAQ">
        <div class="appie-faq-8-area pt-100 pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="appie-section-title">
                        <h3 class="appie-title">FAQ</h3>
                        <p>Frequently Asked Questions</p>
                    </div>
                    <div class="faq-accordion wow fadeInRight mt-30" data-wow-duration="1500ms">
                        <div class="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                            <div class="accrodion active ">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Where can one get the adeo software?</h4>
                                    </div>
                                    <div class="accrodion-content">
                                        <div class="inner">
                                            <p>You can download it via the playstore, Appstore or right here on our website for the windows version.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accrodion  ">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>How much does it cost ?</h4>
                                    </div>
                                    <div class="accrodion-content">
                                        <div class="inner">
                                            <p>There are various bundles which you can find once you download the application. We have bundles for Primary , junior high and senior high . We also have combo bundles and ultimate bundles.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="accrodion ">
                                <div class="accrodion-inner">
                                    <div class="accrodion-title">
                                        <h4>Do you charge for updates ?</h4>
                                    </div>
                                    <div class="accrodion-content">
                                        <div class="inner">
                                            <p>All updates are free once you have a subscription. We update monthly.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-play-box">
            <div class="play-btn">
                <a class="appie-video-popup" href="https://www.youtube.com/watch?v=BduMugJnLMA"><i
                        class="fas fa-play"></i></a>
            </div>
            <div class="faq-play-counter">
                <div class="box-1">
                    <h4 class="title">10k</h4>
                    <span>App Downloads</span>
                </div>
                <div class="box-1 box-2">
                    <h4 class="title">5k</h4>
                    <span>App Downloads</span>
                </div>
            </div>
        </div>
    </div>
    </div>
    )
}

export default Enterprise
